import { createSelector } from 'reselect';

import { getActive } from './song.helpers';
import { RootState } from './store';

export const keySelector = createSelector(
  (state: RootState) => getActive(state.song),
  (active) => active.key
);

export const scaleSelector = createSelector(
  (state: RootState) => getActive(state.song),
  (active) => active.scale
);

export const degreeSelector = createSelector(
  (state: RootState) => getActive(state.song),
  (active) => active.degree
);

export const chordSelector = createSelector(
  (state: RootState) => getActive(state.song),
  (active) => active.chord
);

export const positionSelector = createSelector(
  (state: RootState) => getActive(state.song),
  (active) => active.position
);

export const pickSelector = createSelector(
  (state: RootState) => getActive(state.song),
  (active) => active.pick
);

export const flowSelector = createSelector(
  (state: RootState) => getActive(state.song),
  (active) => active.flow
);

export const tracksSelector = createSelector(
  (state: RootState) => state.song,
  (song) => song.tracks
);

export const activeBlockIdSelector = createSelector(
  (state: RootState) => state.song,
  (song) => song.active.blockId
);


export const activeTrackIdSelector = createSelector(
  (state: RootState) => state.song,
  (song) => song.active.trackId
);

export const tempoSelector = createSelector(
  (state: RootState) => state.song,
  (song) => song.transport.tempo
);