import * as Tone from 'tone';

import guitarSampler from 'lib/samplers/guitar';

type Callback = () => void

export const simple = (callback: Callback, sampler = guitarSampler) => (time: number, pitch: any) => {
  sampler.triggerAttackRelease(pitch, "8n", time);
  Tone.Draw.schedule(callback, time);
};

export const strum = (callback: Callback, sampler = guitarSampler) => (time: any, pitch: any) => {
  const interval = Tone.Time('64n').toSeconds(); 
  const pitches = ([pitch] as any).flat();
  pitches.forEach(
    (pitch: string, index: number) => sampler.triggerAttackRelease(pitch, '4*1n', `+${index*interval}`)
  );
  Tone.Draw.schedule(callback, time);
};

export const doubleStrum = (callback: Callback, sampler = guitarSampler) => (time: any, pitch: any) => {
  const interval = Tone.Time('128n').toSeconds(); 
  const pitches = ([pitch] as any).flat();
  const offset = (pitches.length*interval) + Tone.Time('4n').toSeconds() + Tone.Time('16n').toSeconds();
  pitches.forEach(
    (pitch: string, index: number) => {
      sampler.triggerAttackRelease(pitch, '8n', `+${index*interval}`);
      sampler.triggerAttackRelease(pitch, '4*1n', `+${offset + (index*interval)}`);
    }
  );
  Tone.Draw.schedule(callback, time);
};

export const arpeggio = (callback: Callback, sampler = guitarSampler) => (time: any, pitch: any) => {
  const pitches = ([pitch] as any).flat();
  const interval = Tone.Time('1m').toSeconds() / pitches.length;
  pitches.forEach(
    (pitch: string, index: number) => sampler.triggerAttackRelease(pitch, '4n', `+${index*interval}`)
  );
  Tone.Draw.schedule(callback, time);
};
