
import { useEffect, useRef, MutableRefObject } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as Tone from 'tone';

import { arpeggio, simple, strum } from "lib/audio";
import { setBlockId } from "state/song.reducer";
import { tracksSelector } from "state/song.selectors";
import { Block, Track } from 'types';

const applyFlow = (notes: Tone.Unit.Note[], flow?: Block['flow']) => {
  if (flow === 'reverse') {
    return notes.reverse(); 
  }
  if (flow === 'alternate') {
    const store = [];
    for (let i=0; i<notes.length-3; i++) {
      store.push(notes[i], notes[i+2]);
    }
    return store;
  }

  if (flow === 'down-up') {
    return [...notes, ...notes.slice(1).reverse().slice(1)];
  }

  return notes;
};

const getPickHandler = (pick: Block['pick'] | undefined, callback: () => void) => {
  if (pick === 'strum') {
    return strum(callback); 
  }
  if (pick === 'arpeggio') {
    return arpeggio(callback); 
  }
  return simple(callback);
};

const cookPart = (
  notes: Tone.Unit.Note[],
  callback: () => void,
  pick?: Block['pick'], 
  flow?: Block['flow'], 
): Tone.Part => {
  const part = new Tone.Part(
    getPickHandler(pick, callback),
    [
      [
        "0:0:0", 
        notes ? applyFlow(notes, flow) : []
      ]
    ]);
  
  return part;
};

const buildPart = (callback: () => void) => (block: Partial<Block>, index: number): Tone.Part | undefined => {
  const position = block.position || 0;
  const notes = block.chord?.positions?.[position].midi.map(note => Tone.Frequency(note, "midi").toNote());

  return cookPart(
    notes || [], 
    callback,
    block.pick, 
    block.flow
  ).start(index === 0 ? 0 : `${index}m`);
};

type TrackParts = { 
  id: Track['id']
  parts: (Tone.Part | undefined)[] 
}

export const useBlockBuilder = (): MutableRefObject<TrackParts[] | undefined> => {
  const tracks = useSelector(tracksSelector);
  const dispatch = useDispatch();
  const ref = useRef<TrackParts[]>();

  useEffect(() => {
    const trackMap = tracks.map(track => ({
      id: track.id,
      parts: track.blocks.map((block, index) => {
        const builder = buildPart(() => dispatch(setBlockId(block.id)));
        return builder(block, index);
      })
    }));
    ref.current = trackMap;

    const dispose = () => trackMap.forEach(
      ({ parts }) => parts.forEach(part => part?.dispose())
    );
    return dispose;
  }, [tracks, dispatch]);

  return ref;
};