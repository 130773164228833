import React, { FunctionComponent } from 'react';

interface Props {
  root?: string
  quality?: string
  onKeyChange: (key: string) => void
  onScaleChange: (scale: string) => void
}

export const CircleOfFifths: FunctionComponent<Props> = ({ root, quality, onKeyChange, onScaleChange }) => {

  const onMajorKeyChange = (key: string) => {
    onKeyChange(key);
    if (quality !== 'major') {
      onScaleChange('major');
    }
  };

  const onMinorKeyChange = (key: string) => {
    onKeyChange(key);
    if (quality !== 'minor') {
      onScaleChange('aeolian');
    }
  };

  return (
    <svg className="c-cof__container" viewBox="0 0 110 110">

      {/* Outer circle (major) */}

      {/* F */}
      <path
        className={`${root === 'F' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        onClick={() => onMajorKeyChange('F')} 
        d="M55,55 L5,55.00000000000001 A50,50 0 0,1 11.698729810778069,29.999999999999993z"
      />
      <text 
        x={-23} 
        y={46} 
        onClick={() => onMajorKeyChange('F')} 
        className="c-cof__major">
          F
      </text>
      
      {/* C */}
      <path
        className={`${root === 'C' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        onClick={() => onMajorKeyChange('C')} 
        d="M55,55 L11.698729810778069,29.999999999999993 A50,50 0 0,1 29.99999999999998,11.698729810778076z"
      />
      <text 
        x={-3.5}
        y={40} 
        onClick={() => onMajorKeyChange('C')} 
        className="c-cof__major">
          C
      </text>

      {/* G */}
      <path
        onClick={() => onMajorKeyChange('G')}
        className={`${root === 'G' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L29.99999999999998,11.698729810778076 A50,50 0 0,1 54.99999999999999,5z"
      />
      <text 
        x={16} 
        y={46} 
        onClick={() => onMajorKeyChange('G')} 
        className="c-cof__major">
        G
      </text>
      
      {/* D */}
      <path
        onClick={() => onMajorKeyChange('D')}
        className={`${root === 'D' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L54.99999999999999,5 A50,50 0 0,1 80,11.698729810778069z"
      />
      <text 
        x={31} 
        y={61} 
        onClick={() => onMajorKeyChange('D')} 
        className="c-cof__major">
        D
      </text>

      {/* A */}
      <path
        onClick={() => onMajorKeyChange('A')}
        className={`${root === 'A' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L80,11.698729810778069 A50,50 0 0,1 98.30127018922192,29.99999999999998z"
      />
      <text 
        x={37} 
        y={80} 
        onClick={() => onMajorKeyChange('A')}
        className="c-cof__major">
        A
      </text>

      {/* E */}
      <path
        onClick={() => onMajorKeyChange('E')}
        className={`${root === 'E' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L98.30127018922192,29.99999999999998 A50,50 0 0,1 105,54.999999999999986z"
      />
      <text 
        x={32} 
        y={101} 
        onClick={() => onMajorKeyChange('E')}
        className="c-cof__major">
        E
      </text>

      {/* B */}
      <path
        onClick={() => onMajorKeyChange('B')}
        className={`${root === 'B' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L105,55 A50,50 0 0,1 98.30127018922194,80z"
      />
      <text 
        x={17} 
        y={116} 
        onClick={() => onMajorKeyChange('B')}
        className="c-cof__major">
        B
      </text>

      {/* G♭ */}
      <path
        onClick={() => onMajorKeyChange('Gb')}
        className={`${root === 'Gb' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L98.30127018922194,80 A50,50 0 0,1 80,98.30127018922192z"
      />
      <text 
        x={-5} 
        y={121}
        onClick={() => onMajorKeyChange('Gb')}
        className="c-cof__major">
        G♭
      </text>

      {/* D♭ */}
      <path
        onClick={() => onMajorKeyChange('Db')}
        className={`${root === 'Db' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L80,98.30127018922192 A50,50 0 0,1 55,105z"
      />
      <text 
        x={-25} 
        y={116} 
        onClick={() => onMajorKeyChange('Db')}
        className="c-cof__major">
        D♭
      </text>

      {/* Ab */}
      <path
        onClick={() => onMajorKeyChange('Ab')}
        className={`${root === 'Ab' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L55,105 A50,50 0 0,1 30.00000000000001,98.30127018922194z"
      />
      <text 
        x={-39} 
        y={101}
        onClick={() => onMajorKeyChange('Ab')}
        className="c-cof__major">
        A♭
      </text>

      {/* Eb */}
      <path
        onClick={() => onMajorKeyChange('Eb')}
        className={`${root === 'Eb' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L30.00000000000001,98.30127018922194 A50,50 0 0,1 11.698729810778062,80z"
      />
      <text 
        x={-43} 
        y={80}
        onClick={() => onMajorKeyChange('Eb')}
        className="c-cof__major">
        E♭
      </text>

      {/* Bb */}
      <path
        onClick={() => onMajorKeyChange('Bb')}
        className={`${root === 'Bb' && quality === 'major' ? 'c-cof__selected' : 'c-cof__outer'}`}
        d="M55,55 L11.698729810778062,80 A50,50 0 0,1 5,55.00000000000001z"
      />
      <text 
        x={-39} 
        y={61} 
        onClick={() => onMajorKeyChange('Bb')}
        className="c-cof__major">
        B♭
      </text>


      {/* Inner Circle (minor) */}

      {/* Abm */}
      <path
        className={`${root === 'Ab' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('Ab')}
        d="M55,55 L85,55 A30,30 0 0,1 80.98076211353316,70z"
      />
      <text 
        x={8} 
        y={100} 
        onClick={() => onMinorKeyChange('Ab')}
        className="c-cof__minor">
        A♭m
      </text>

      {/* Ebm */}
      <path
        className={`${root === 'Eb' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('Eb')}
        d="M55,55 L80.98076211353316,70 A30,30 0 0,1 70,80.98076211353316z"
      />
      <text 
        x={-4} 
        y={103}
        onClick={() => onMinorKeyChange('Eb')}
        className="c-cof__minor">
        E♭m
      </text>

      {/* Bbm */}
      <path
        className={`${root === 'Bb' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('Bb')}
        d="M55,55 L70,80.98076211353316 A30,30 0 0,1 55,85z"
      />
      <text 
        x={-17} 
        y={100} 
        onClick={() => onMinorKeyChange('Bb')}
        className="c-cof__minor">
        B♭m
      </text>

      {/* Fm */}
      <path
        className={`${root === 'F' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('F')}
        d="M55,55 L55,85 A30,30 0 0,1 40.00000000000001,80.98076211353316z"
      />
      <text 
        x={-25} 
        y={91} 
        onClick={() => onMinorKeyChange('F')}
        className="c-cof__minor">
        Fm
      </text>

      {/* Cm */}
      <path
        className={`${root === 'C' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('C')}
        d="M55,55 L40.00000000000001,80.98076211353316 A30,30 0 0,1 29.01923788646684,70z"
      />
      <text 
        x={-27} 
        y={79} 
        onClick={() => onMinorKeyChange('C')}
        className="c-cof__minor">
        Cm
      </text>
      
      {/* Gm */}
      <path
        className={`${root === 'G' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('G')}
        d="M55,55 L29.01923788646684,70 A30,30 0 0,1 25,55.00000000000001z"
      />
      <text 
        x={-25} 
        y={67} 
        onClick={() => onMinorKeyChange('G')}
        className="c-cof__minor">
        Gm
      </text>

      {/* Dm */}
      <path
        className={`${root === 'D' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('D')}
        d="M55,55 L25,55.00000000000001 A30,30 0 0,1 29.019237886466843,40z"
      />
      <text 
        x={-16} 
        y={58} 
        onClick={() => onMinorKeyChange('D')}
        className="c-cof__minor">
        Dm
      </text>

      {/* Am */}
      <path
        className={`${root === 'A' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('A')} 
        d="M55,55 L29.019237886466843,40 A30,30 0 0,1 39.999999999999986,29.019237886466847z"
      />
      <text 
        x={-3.5}
        y={55}
        onClick={() => onMinorKeyChange('A')} 
        className="c-cof__minor">
        Am
      </text>

      {/* Em */}
      <path
        className={`${root === 'E' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('E')} 
        d="M55,55 L39.999999999999986,29.019237886466847 A30,30 0 0,1 54.99999999999999,25z"
      />
      <text 
        x={9} 
        y={58} 
        onClick={() => onMinorKeyChange('E')} 
        className="c-cof__minor">
        Em
      </text>

      {/* Bm */}
      <path
        className={`${root === 'B' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('B')} 
        d="M55,55 L54.99999999999999,25 A30,30 0 0,1 70,29.019237886466843z"
      />
      <text 
        x={17} 
        y={67} 
        onClick={() => onMinorKeyChange('B')} 
        className="c-cof__minor">
        Bm
      </text>
      
      {/* Gbm */}
      <path
        className={`${root === 'Gb' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('Gb')} 
        d="M55,55 L70,29.019237886466843 A30,30 0 0,1 80.98076211353316,39.999999999999986z"
      />
      <text 
        x={17} 
        y={79}
        onClick={() => onMinorKeyChange('Gb')} 
        className="c-cof__minor">
        G♭m
      </text>

      {/* Dbm */}
      <path
        className={`${root === 'Db' && quality === 'minor' ? 'c-cof__selected' : 'c-cof__inner'}`}
        onClick={() => onMinorKeyChange('Db')} 
        d="M55,55 L80.98076211353316,39.999999999999986 A30,30 0 0,1 85,54.99999999999999z"
      />
      <text 
        x={16} 
        y={90} 
        onClick={() => onMinorKeyChange('Db')} 
        className="c-cof__minor">
        D♭m
      </text>

    </svg>
  );
};
