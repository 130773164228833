import { enharmonic } from '@tonaljs/note';
import { Chord } from '@tonaljs/tonal';
import { find, path, propEq } from 'ramda';

import guitar from 'lib/guitar.json';
import { GuitarChordPosition } from 'types/GuitarChordPosition';
import { GuitarChordType } from 'types/GuitarChordType';

type LibChord = {
  key: string;
  suffix: string;
  positions: GuitarChordPosition[];
}

const getPositions = (key: string, chord: string): GuitarChordPosition[] => {
  const allChords: LibChord[] = path(['chords', key], guitar) || path(['chords', enharmonic(key)], guitar) || [];
  const record: LibChord | undefined = find<LibChord>(propEq('suffix', chord))(allChords);

  if (record?.positions?.length) {
    return record?.positions || [];
  }
  //console.log('chord not found', chord);
  return [];
};

export class GuitarChord {
  
  static get(key: string, chordName: string): GuitarChordType {
    const chord = Chord.get(`${key}${chordName}`);
    return {
      ...chord,
      positions: getPositions(key, chordName)
    };
  }

}