import React, { FunctionComponent, ReactNode, useEffect, useRef } from "react";

interface Props {
  onClose: () => void
  open: boolean
  children: ReactNode
}
  
export const Dropdown: FunctionComponent<Props> = ({ onClose, open, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const handler = (evt: MouseEvent) => {
      if (!ref.current?.contains(evt.target as Node)) {
        onClose();
      }
    };
    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  });
  
  const closeStyle = "transition ease-out duration-100 transform opacity-0 scale-95 transform opacity-100 scale-100 hidden";
  const openStyle = "transition ease-in duration-75 transform opacity-100 scale-100 transform opacity-0 scale-95 block";
  
  return (
    <div ref={ref} className={`${open ? openStyle : closeStyle } relative`}>
      <div className="absolute">
        {children}
      </div>
    </div>
  );
};