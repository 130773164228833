import { combineReducers, createStore } from '@reduxjs/toolkit';

import songReducer from 'state/song.reducer';

const rootReducer = combineReducers({
  song: songReducer
});

export const store = createStore(
  rootReducer, /* preloadedState, */
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__() //eslint-disable-line
);

export type RootState = ReturnType<typeof rootReducer>
