import React, { FunctionComponent } from 'react';
import {useDrop } from 'react-dnd';

import { Block } from 'types';

interface Props {
  block: Partial<Block>
  isActive: boolean
  onClick: (evt: any) => void
  onRemove: () => void
}
  
export const TrackBlock: FunctionComponent<Props> = ({ block, isActive, onClick, onRemove }) => {
  const [, dropRef] = useDrop({
    accept: 'Chord',
    drop: () => ({ block })
  });

  return (
    <div ref={dropRef}>
      <div
        ref={node => {
          if (isActive) {
            node?.scrollIntoView({
              block: 'center',
              inline: 'center',
              behavior: 'smooth'
            });
          }
        }}
        onClick={onClick} 
        className={`${isActive ? 'bg-teal-100' : 'bg-teal-400' } relative rounded-md text-black flex-none flex justify-around items-center cursor-pointer w-16 h-full mx-1 flex-col`}
      >
        { block.degree && (
          <>
            <span className="text-sm tracking-tighter font-medium">{block.chord?.symbol}</span>
            <div className="text-center">
              <div className="text-xs font-thin leading-none">{block.key}</div>
              <div className="text-xs leading-none">{block.degree}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
  