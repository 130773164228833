import React, { createContext, ReactNode, FunctionComponent, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import * as Tone from 'tone';

import { useBlockBuilder } from "hooks";
import metronome from "lib/metronome";
import guitarSampler from 'lib/samplers/guitar';
import { tempoSelector, tracksSelector } from "state/song.selectors";


interface Props {
  children: ReactNode;
}

export interface State {
  readonly arpeggiate: (
    notes: string[], 
    options?: { interval?: number, sustain?: number, sampler?: Tone.Sampler }
  ) => void
  togglePlay: () => void;
  isPlaying: boolean;
  canPlay: boolean;
  skip: (measure: string) => void;
  metronome: {
    toggle: () => void;
    mute: boolean
  }
}

export const AudioContext = createContext<State>({
  arpeggiate: () => null,
  togglePlay: () => null,
  metronome: {
    toggle: () => null,
    mute: metronome.mute
  },
  isPlaying: false,
  canPlay: false,
  skip: () => { return; },
});

export const strum: State['arpeggiate'] = (notes, options) => {
  const { 
    interval = Tone.Time('64n').toSeconds(), 
    sampler = guitarSampler 
  } = options || {};
  notes.forEach(
    (note, index) => sampler.triggerAttackRelease(note, '4*1n', `+${index*interval}`)
  );
};

const play = () => {
  Tone.Transport.loop = true;
  Tone.Transport.loopStart = 0;
  Tone.Transport.start("+0.1");
};

const stop = () => Tone.Transport.stop();

const pause = () => Tone.Transport.pause();

const togglePlay = () => {
  if (Tone.Transport.state === "started") {
    pause();
  } else {
    play();
  }
};

const skip = (measure: string) => {
  Tone.Transport.position = measure;
};

export const AudioProvider: FunctionComponent<Props> = ({ children }) => {
  const tempo = useSelector(tempoSelector);
  const tracks = useSelector(tracksSelector);
  const parts = useBlockBuilder();
  const [isPlaying, setIsPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [isMetronomeMuted, setIsMetronomeMuted] = useState(false);

  const toggleMetronome = () => {
    if (isMetronomeMuted) {
      metronome.mute = false;
      setIsMetronomeMuted(false);
    } else {
      metronome.mute = true;
      setIsMetronomeMuted(true);
    }
  };

  useEffect(() => {
    Tone.Transport.bpm.value = tempo;
  }, [tempo]);

  useEffect(() => {
    const blocks = tracks.map(({ blocks }) => blocks.length);
    const max = Math.max(...blocks);
    setCanPlay(max > 0);

    if (max === 0) {
      stop();
    }

    Tone.Transport.loopEnd = `${max}:0:0`;
  }, [tracks]);

  useEffect(() => {
    const callback = () => setIsPlaying(true);
    Tone.Transport.on('start', callback);
    return () => { 
      Tone.Transport.off('start', callback);
    };
  }, []);

  useEffect(() => {
    const callback = () => setIsPlaying(false);
    Tone.Transport.on('pause', callback);
    Tone.Transport.on('stop', callback);
    return () => { 
      Tone.Transport.off('pause', callback);
      Tone.Transport.off('stop', callback);
    };
  }, []);

  const value = {
    isPlaying,
    canPlay,
    skip,
    arpeggiate: (props: any) => strum(props),
    togglePlay,
    metronome: {
      toggle: toggleMetronome,
      mute: isMetronomeMuted
    }
  };

  return (
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
};
