import { compose, groupBy, indexOf, keys, propOr, sortBy } from 'ramda';
import React, { FunctionComponent, useState } from 'react';

import { Quality , ScaleExtended } from 'lib/Scale';


interface Props {
  selected?: string
  values?: ScaleExtended[]
  onChange: (scale: ScaleExtended) => void 
}

const ORDER: Quality[] = [
  'major',
  'minor',
  'diminished',
  'augmented',
  'other'
];

const orderFn = (scale: string) => indexOf(scale, ORDER);

export const ScaleMenu: FunctionComponent<Props> = ({ values = [], selected, onChange }) => {
  const [filter, setFilter] = useState<string | null>(null);

  const byQuality = groupBy<ScaleExtended>(propOr('other', 'quality'));
  
  const scales = byQuality(
    values.filter(scale => scale.name.toLowerCase().includes(filter?.toLowerCase() || ''))
  );

  const groupedScales = compose(
    sortBy(orderFn),
    keys
  )(scales);

  return (
    <div className="mt-2 w-56 rounded-md shadow-lg bg-white divide-y divide-gray-100" role="menu" aria-orientation="vertical">
      <input 
        onChange={evt => setFilter(evt.target.value)}
        type="text" 
        placeholder="Search by name..." 
        className="focus:outline-none mx-4 mt-4 mb-2 w-auto sm:text-sm rounded-md font-light"/>
      <div className="relative overflow-scroll h-72 ">
        {groupedScales.map(key => (
          <div key={key}>
            <p className="block px-4 py-2 bg-white font-bold text-xs uppercase sticky top-0">{key}</p>
            {scales[key].sort((a, b) => a.name < b.name ? -1 : 1).map(scale => (
              <p 
                key={scale.name}
                tabIndex={0}
                className={`block capitalize px-4 py-2 text-sm text-gray-700 hover:bg-gray-300 
              cursor-pointer focus:bg-gray-300 focus:outline-none ${scale.name === selected ? 'bg-gray-300' : ''}`}
                onClick={() => onChange(scale)}>
                {scale.name}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
