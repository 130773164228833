import { Midi } from '@tonaljs/tonal';
import React, { FunctionComponent, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';

import { AudioContext } from 'providers/AudioProvider';
import { setActive } from 'state/song.reducer';
import { chordSelector, pickSelector, positionSelector } from 'state/song.selectors';
import { Block, GuitarChordPosition } from 'types';
import { ChordDetails } from 'ui/ChordDetails/ChordDetails';

export const ChordPanel: FunctionComponent = () => {
  const chord = useSelector(chordSelector);
  const position = useSelector(positionSelector);
  const pick = useSelector(pickSelector);
  const dispatch = useDispatch();
  const { arpeggiate } = useContext(AudioContext);

  const selectedPosition = chord?.positions?.[position || 0];

  const play = (midiNotes: number[]) => {
    const notes = midiNotes.map(midiNote => Midi.midiToNoteName(midiNote)) || [];
    arpeggiate(notes);
  };

  const onPositionChange = (position: GuitarChordPosition, index: number) => {
    dispatch(setActive({ position: index }));
    play(position.midi);
  };

  const onPlayPropsChange = (props: Partial<Block>) => {
    dispatch(
      setActive({ 
        ...props
      })
    );
  };
  
  const positions = chord?.positions?.map((pos, i) => (
    { 
      ...pos,
      selected:  position === i
    }
  )) || [];

  return (
    <ChordDetails
      compact={isMobile}
      active={selectedPosition} 
      positions={positions} 
      onPositionChange={onPositionChange}
      onPlayPropsChange={onPlayPropsChange} 
      title={chord?.symbol}
      activePick={pick}
      subtitle={chord?.intervals.join(' ')}
    />
  );
};
