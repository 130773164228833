import { flatten, groupBy, isEmpty } from "ramda";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { Chord } from "components/Chord";
import { Mode } from "lib/Scale";
import { chordSelector } from "state/song.selectors";
import { GuitarChordType } from "types/GuitarChordType";
interface Props extends Partial<Mode> {
  label: string;
  chords: GuitarChordType[];
}

export const Degree: FunctionComponent<Props> = ({
  numeral,
  label,
  intervals,
  chords,
}) => {
  const chord = useSelector(chordSelector);
  const chordGroups = groupBy<GuitarChordType>(
    (chord) => `${chord.intervals.length}`
  )(chords.filter(({ positions }) => !isEmpty(positions)));

  const chordList = flatten(
    Object.keys(chordGroups).map((key) =>
      chordGroups[key].map((validChord) => validChord)
    )
  );

  return (
    <div className="c-degree pt-2">
      <div className="c-degree__inner pt-2">
        <div className="flex justify-between mx-4">
          <div className="flex bg-gray-300 py-1 px-1 rounded-full">
            <span className="text-xs font-bold shadow-sm text-gray-200 bg-gray-900 rounded-full h-6 w-6 flex items-center justify-center">
              {numeral}
            </span>
            <span className="text-sm text-gray-800 px-2">{label}</span>
          </div>
          <div className="flex items-center">
            {intervals?.map((interval) => (
              <span
                key={`interval-${interval}`}
                className="rounded-full shadow-sm opacity-75 w-4 h-4 mr-1 inline-flex flex-shrink-0 items-center justify-center text-sm  text-teal-700"
              >
                {interval}
              </span>
            ))}
          </div>
        </div>
        <div
          className="overflow-x-scroll overflow-y-hidden overflow-scrolling flex flex-no-wrap px-4"
          onContextMenu={(evt) => evt.preventDefault()}
        >
          {chordList
            .sort((a, b) => (a.symbol.length < b.symbol.length ? -1 : 1))
            .map((validChord) => (
              <Chord
                key={validChord.symbol}
                degree={numeral}
                selected={chord?.symbol === validChord.symbol}
                chord={validChord}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
