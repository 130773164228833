import { simplify } from "@tonaljs/note";
import React, { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useSelector } from "react-redux";

import { ChordPanel } from "components/ChordPanel";
import { MobileComposer } from "components/Composer";
import { Degree } from "components/Degree";
import { FretboardContainer as Fretboard } from "components/Fretboard";
import { Options } from "components/Options";
import { GuitarChord } from "lib/GuitarChord";
import { Mode, Scale } from "lib/Scale";
import { keySelector, scaleSelector } from "state";

const options = {
  delayTouchStart: 200,
  scrollAngleRanges: [
    { start: 350 },
    { end: 10 },
    {
      start: 170,
      end: 190,
    },
  ],
};

export const Mobile: FunctionComponent = () => {
  const key = useSelector(keySelector);
  const scale = useSelector(scaleSelector);
  const modes = key && scale ? Scale.modes(key, scale) : [];

  return (
    <DndProvider
      backend={isMobile ? TouchBackend : HTML5Backend}
      options={options}
    >
      <Options />
      <Fretboard />

      <ChordPanel />

      <div className="mt-4" style={{ marginBottom: "8.1rem" }}>
        {modes &&
          modes.map(
            ({ key, name, numeral, intervals }: Mode, index: number) => (
              <Degree
                key={`chord-${index}`}
                numeral={numeral}
                intervals={intervals}
                label={`${simplify(key)} ${name}`}
                chords={Scale.chords(key, name).map((chord) =>
                  GuitarChord.get(key, chord)
                )}
              />
            )
          )}
      </div>

      <div className="fixed w-full bottom-0 z-40">
        <MobileComposer />
      </div>
    </DndProvider>
  );
};
