import React, { FunctionComponent } from 'react';

import { Mobile } from 'components/Mobile';

export const Home: FunctionComponent = () => (
  <div className="select-none">
    <Mobile />
  </div>
);

