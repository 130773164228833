import { createAction, createReducer, nanoid } from '@reduxjs/toolkit';
import { isNil } from 'ramda';

import { Block, File, Track, Transport } from 'types';

import { getActive, getTrack } from './song.helpers';

export interface State extends File {
  active: {
    trackId: Track['id'],
    blockId?: Block['id'] 
  }
}

export const setTransport = createAction<Partial<Transport>>('view/transport');

export const setActive = createAction<Partial<Block>>('view/active');

export const setBlockId = createAction<Block['id'] | undefined>('view/blockId');

export const addTrack = createAction('track/add', (args: any) => ({ 
  payload: {
    ...args,
    id: nanoid() 
  }
}));

export const deleteTrack = createAction('track/delete');

export const appendBlock = createAction('track/block/add', (args?: any) => ({ 
  payload: {
    ...args,
    id: nanoid(),
    pick: 'strum'
  }
}));

export const setBlock = createAction<Partial<Block>>('track/block/update');

export const deleteBlock = createAction<{ trackId: Track['id'], id: Block['id'] }>('track/block/delete');

const trackId = nanoid();

const initialState: State = {
  active: {
    trackId
  },
  metadata: {
    author: '',
    name: 'untitled-1'
  },
  transport: {
    tempo: 100,
    signature: '4/4'
  },
  tracks: [
    {
      id: trackId,
      instrument: 'guitar',
      blocks: [],
      playground: {
        key: 'C',
        scale: 'major'
      }
    }
  ]
};
 
export default createReducer(initialState, (builder) => {
  builder
    .addCase(setTransport, (state, action) => {
      state.transport = {
        ...state.transport,
        ...action.payload
      };
    })
    .addCase(setBlockId, (state, action) => {
      state.active.blockId = action.payload;
    })
    .addCase(setBlock, (state, { payload: { id, ...block }}) => {
      const track = getTrack(state.tracks, state.active.trackId);
      const idx = track?.blocks.findIndex(props => props.id === id) || 0;
      Object.assign(track?.blocks[idx], block);
    })
    .addCase(setActive, (state, action) => {
      const active = getActive(state);
      Object.assign(active, action.payload);

      if (state.active.blockId) {
        const track = getTrack(state.tracks, state.active.trackId);
        Object.assign(track?.playground, action.payload);
      }
    })
    .addCase(addTrack, (state, action) => {
      state.tracks.push(action.payload);
    })
    .addCase(appendBlock, (state, { payload: { trackId, ...block }}) => {
      const track = getTrack(state.tracks, trackId);
      track?.blocks.push(block);
      state.active.blockId = block.id;
    })
    .addCase(deleteBlock, (state, { payload: { trackId, ...block }}) => {
      const track = getTrack(state.tracks, trackId);
      const index = track?.blocks.findIndex(({ id }) => id === block.id);

      if (!isNil(index)) {
        track?.blocks.splice(index, 1);
      }
    });
});