import { times } from "ramda";

import { MatrixCallback } from "types";

export function getMatrix<T>(
  rows: number,
  columns: number,
  cb: MatrixCallback<T>
): T[][] {
  return times(y => times(x => cb(x, y + 1), columns), rows);
}
