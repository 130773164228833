import { fromSemitones } from "@tonaljs/interval";
import { transposeBy, enharmonic } from "@tonaljs/note";
import { note } from "@tonaljs/tonal";
import {
  flatten,
  nth,
  map,
  or,
  pathSatisfies,
  equals,
  merge,
  cond,
  T,
  curry,
  identity,
  Pred
} from "ramda";

import { Fret , MatrixCallback , Note } from "types";

import { getMatrix } from "./math";

export const NOTES: Note[] = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B"
];

export const DEFAULT_ROOTS: (string | null)[] = [
  null,
  "E4",
  "B3",
  "G3",
  "D3",
  "A2",
  "E2"
];

export const addSemitones = (
  note: string | null | undefined,
  semitones: number
): ReturnType<ReturnType<typeof transposeBy>> => transposeBy(fromSemitones(semitones))(note || "");

export const defaultFretListCb = (fret: number, string: number): Fret => {
  const n = note(addSemitones(nth(string, DEFAULT_ROOTS), fret));
  return {
    string,
    fret,
    note: n,
    label: n.pc,
    visible: true
  };
};

export const getFretList = (
  frets: number,
  strings: number,
  cb: MatrixCallback<Fret> = defaultFretListCb
): Fret[] => flatten(getMatrix(strings, frets, cb));

export function extendIf<T>(list: T, condition: Pred, object: { [key: string]: string | boolean } ): T {
  return map(cond([[condition, merge(object)], [T, identity]]), list);
}

const isHarmonicOrEnharmonic = curry((note, value) =>
  or(equals(value, note), equals(value, enharmonic(note)))
);

export const getFretsWithClassNames = (
  frets = getFretList(25, 6),
  note: string,
  classNames: string
): Fret[] =>
  extendIf(frets, pathSatisfies(isHarmonicOrEnharmonic(note), ["note", "pc"]), {
    classNames
  });
