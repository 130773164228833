import { enharmonic } from "@tonaljs/note";
import { includes, or } from "ramda";
import React, { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";

import { Fretboard } from "components/Fretboard";
import { Scale } from "lib/Scale";
import { getFretList, getFretsWithClassNames, extendIf } from "lib/utils";
import { keySelector, scaleSelector } from "state";

const getList = (scale: string, key: string) => {
  const fretlist = getFretList(25, 6);
  const fretsWithRoot = getFretsWithClassNames(fretlist, key, "c-fret__root");
  const fretsWithActiveAndRoot = extendIf(
    fretsWithRoot,
    ({ note: { pc } }) => {
      const { notes } = Scale.get(key, scale);
      return or(includes(pc)(notes), includes(pc)(notes.map(enharmonic)));
    },
    { active: true }
  );
  return fretsWithActiveAndRoot;
};

export const FretboardContainer: FunctionComponent = () => {
  const key = useSelector(keySelector);
  const scale = useSelector(scaleSelector);
  const list = useMemo(
    () => (scale && key ? getList(scale, key) : []),
    [scale, key]
  );

  return (
    <div className="flex flex-no-wrap overflow-x-auto overflow-scrolling  pb-4">
      <Fretboard list={list} />
    </div>
  );
};
