import { always as noop, map } from "ramda";
import React, { FunctionComponent } from "react";

import { Fret } from "components/Fret";
import { Fret as FretType } from "types";

interface Props {
  list: FretType[];
  onClick?: (f: FretType) => void;
}

export const Fretboard: FunctionComponent<Props> = ({
  list,
  onClick = noop
}) => (
  <div className="c-fretboard mb-4 relative">
    {map(
      ({ string, fret, ...props }) => (
        <Fret
          key={`s${string}-f${fret}`}
          string={string}
          fret={fret}
          onClick={() => onClick({ string,
            fret,
            ...props })}
          {...props}
        />
      ),
      list
    )}
    <div className="absolute c-fretboard__bg" style={{ width: '96%' }}/>
  </div>
);

Fretboard.defaultProps = {
  list: []
};
