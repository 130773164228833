import { find, findIndex, propEq } from "ramda";

import { Block, Track } from "types";

import { State } from "./song.reducer";

export const getActive = (state: Pick<State, 'active' | 'tracks'>): Partial<Block> => {
  const trackIdx = findIndex(propEq('id', state.active.trackId), state.tracks);
  
  if (state.active.blockId) {
    const trackIdx = findIndex(propEq('id', state.active.trackId), state.tracks);
    const blockIdx = findIndex(propEq('id', state.active.blockId), (state.tracks[trackIdx].blocks as Block[]) || []);
    return state.tracks[trackIdx].blocks[blockIdx];
  }
  
  return state.tracks[trackIdx].playground;
};

export const getTrack = (tracks: Track[], trackId: Track['id']): Track | undefined => find(propEq('id', trackId), tracks);
