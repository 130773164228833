import ChordDiagram from '@tombatossals/react-chords/lib/Chord';
import { motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';

import guitar from 'lib/guitar.json';
import { Block, GuitarChordPosition } from 'types';

const instrument = {
  ...guitar.main,
  keys: guitar.keys,
  tunings: guitar.tunings
};
const lite = false; // defaults to false if omitted

type GuitarChordPositionWithSelected = GuitarChordPosition & { selected: boolean }

interface Props {
  compact?: boolean
  active?: GuitarChordPosition
  positions: GuitarChordPositionWithSelected[]
  title?: string
  subtitle?: string
  onPositionChange: (position: GuitarChordPosition, index: number) => void
  activePick: Block['pick']
  onPlayPropsChange: (props: Partial<Block>) => void
}

export const ChordDetails: FunctionComponent<Props> = ({ 
  compact = false,
  active,
  positions,
  title,
  subtitle,
  activePick,
  onPositionChange,
  onPlayPropsChange
}) => {
  
  if (!active) {
    return null;
  }

  if (compact) {
    return (
      <div className="rounded-lg px-1 bg-gradient-to-b to-gray-200 from-gray-100 border-b border-gray-500 h-full pb-2 flex-none w-full sticky z-10" style={{ top: '4.2rem' }}>
        <div className="flex items-center">
          <div className="w-40 flex-none">
            <ChordDiagram
              chord={active}
              instrument={instrument}
              lite={lite}
            />
          </div>
          <div>
            <div className="flex-1 text-center">
              <div className="text-2xl font-semibold">{title}</div>
              <span className="text-xs italic whitespace-no-wrap">{subtitle}</span>
            </div>
            <div className="flex overflow-scroll mt-2">
              {positions?.map((position, index) => (
                <div
                  className="cursor-pointer"
                  key={`chord-position-${index}`}
                  onClick={() => onPositionChange(position, index)}
                >
                  <ChordDiagram
                    chord={position}
                    instrument={instrument}
                    lite={lite}
                  />
                  { position.selected && (
                    <div 
                      style={{
                        width: '100%',
                        height: 3 
                      }} 
                      className="bg-teal-400"/>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>     
    );
  }
      
  return (
    <div className="relative rounded-lg px-2 h-full w-96 mt-2 mx-auto">
      <div className="absolute top-0 left-0 mt-1 ml-2">
        <span
          className={`cursor-pointer text-xs ${activePick === 'strum' ? 'font-bold' : ''}`}
          onClick={() => onPlayPropsChange({ 
            pick: 'strum',
            flow: undefined 
          })}>
            Strum
        </span>
        <span 
          className={`cursor-pointer text-xs mx-1 ${activePick === 'arpeggio' ? 'font-bold' : ''}`}
          onClick={() => onPlayPropsChange({ 
            pick: 'arpeggio',
            flow: 'down-up' 
          })}>
            Arpeggio
        </span>
      </div>
      <div className="flex justify-evenly items-center">
        <div className="flex-1 text-center">
          <div className="text-2xl font-semibold">{title}</div>
          <span className="text-xs italic whitespace-no-wrap">{subtitle}</span>
        </div>
        <div className="w-48 flex-none">
          <ChordDiagram
            chord={active}
            instrument={instrument}
            lite={lite}
          />
        </div>
      </div>

      <div className="grid grid-flow-row grid-cols-7 mt-2">
        {positions?.map((position, index) => (
          <motion.div
            className="cursor-pointer"
            key={`chord-position-${index}`}
            whileHover={{ scale: 1.3 }}
            onClick={() => onPositionChange(position, index)}
          >
            <ChordDiagram
              chord={position}
              instrument={instrument}
              lite={lite}
            />
            { position.selected && (
              <div 
                style={{
                  width: '100%',
                  height: 3 
                }} 
                className="bg-yellow-400"/>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};