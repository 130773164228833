import { 
  get as getScale, 
  modeNames,
  scaleChords,
} from "@tonaljs/scale";
import { all, ScaleType } from "@tonaljs/scale-dictionary";
import { Interval, RomanNumeral } from "@tonaljs/tonal";
import { all as every } from "ramda";

export interface Mode {
  key: string
  name: string
  numeral: string
  intervals: string[]
}

export type Quality = 'major' | 'minor' | 'diminished' | 'augmented' | 'other'

const getQuality = (intervals: string[]): Quality => {
  if (every((interval: string) => intervals.includes(interval))(['1P', '2m', '2M']) ||
    every((interval: string) => !intervals.includes(interval))(['3m', '3M', '5d', '5P', '5A'])) {
    return 'other';
  }
  if (every((interval: string) => intervals.includes(interval))(['1P', '3M', '5A'])) {
    return 'augmented';
  }
  if (every((interval: string) => intervals.includes(interval))(['1P', '3m', '5P'])) {
    return 'minor';
  }
  if (every((interval: string) => intervals.includes(interval))(['1P', '3m', '5d'])) {
    return 'diminished';
  }
  if (every((interval: string) => intervals.includes(interval))(['1P', '3M', '5P'])) {
    return 'major';
  }
  return 'other';
};

export type ScaleExtended = ScaleType & { quality: Quality };

const toMinor = (step: string): string => step.replace(/I/g, 'i').replace(/V/g, 'v');

const getNumeral = (scale: ScaleExtended, step: string): string => {
  let result = step;

  if (scale.quality === 'minor') {
    result = toMinor(step);
  }
  if (scale.quality === 'diminished') {
    result = `${toMinor(step)}º`;
  }
  if (scale.quality === 'augmented') {
    result = `${step}+`;
  }

  return result.replace(/b/g, '♭');
};

export class Scale {

  static getAll(): ScaleExtended[] {
    return all().map(scale => ({
      ...scale,
      quality: getQuality(scale.intervals)
    }));
  }
  
  static get(key: string, name: string): ReturnType<typeof getScale> & { quality: Quality } {
    const scale = getScale(`${key} ${name}`);
    return {
      ...scale,
      quality: getQuality(scale.intervals)
    };
  }

  static modes(key: string, name: string): Mode[] {
    const degrees = modeNames(`${key} ${name}`);
    const [rootKey, rootName] = degrees[0];
    const root = this.get(rootKey, rootName);

    return degrees.map(
      ([modeKey, modeName], i) => ({
        key: modeKey,
        name: modeName,
        intervals: this.get(modeKey, modeName)?.intervals.map(int => int
          .replace('M', '')
          .replace('P', '')
          .replace('m', '♭')
          .replace('d', '-')
          .replace('A', '+')
        ),
        numeral: getNumeral(
          this.get(modeKey, modeName), 
          RomanNumeral.get(Interval.get(root.intervals[i])).name
        )
      })
    );
  }

  static chords(key: string, name: string): ReturnType<typeof scaleChords> {
    return scaleChords(`${key} ${name}`);
  }
}