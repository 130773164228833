import * as Tone from 'tone';

export default new Tone.Sampler({
  urls: {
    E2: "E2.mp3",
    A2: "A2.mp3",
    D3: "D3.mp3",
    G3: "G3.mp3",
    B3: "B3.mp3",
    E4: "E4.mp3",

    E3: 'E3.mp3',
    A3: "A3.mp3",
    D4: "D4.mp3",
    G4: "G4.mp3",
    B4: "B4.mp3",
    E5: "E5.mp3",
  },
  baseUrl: "/assets/samples/guitar/m2-clean/",
  release: 0.5,
  attack: 0
}).toDestination();