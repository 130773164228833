import React, { FunctionComponent, useState, MouseEvent } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { CircleOfFifths } from "components/CircleOfFifths";
import { Dropdown } from "components/Dropdown";
import { Scale, ScaleExtended } from "lib/Scale";
import { keySelector, scaleSelector } from "state";
import { setActive } from "state/song.reducer";
import { ScaleMenu } from "ui/ScaleMenu";

const scales = Scale.getAll();

interface Props {
  className?: string
}

export const Options: FunctionComponent<Props> = ({ className = 'sticky'}) => {
  const key = useSelector(keySelector);
  const scale = useSelector(scaleSelector);
  const currentScale = scales.find(sc => sc.name === scale);

  const dispatch = useDispatch();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isKeyOpen, setKeyOpen] = useState(false);


  const setKey = (key: string) => dispatch(
    setActive({ 
      key,
      degree: undefined,
      chord: undefined,
      position: undefined
    })
  );

  const setScale = (scale: string) => dispatch(
    setActive({ 
      scale,
      degree: undefined,
      chord: undefined,
      position: undefined
    })
  );

  if (!key || !scale) {
    return null;
  }

  return (
    <nav className={`bg-gray-800 ${className} px-3 py-3 w-full z-30 top-0 flex justify-between`}>
      <div className="flex flex-nowrap w-1/2">
        <div className="w-auto pl-3">
          <button onClick={(evt: MouseEvent) => {
            evt?.stopPropagation();
            setKeyOpen(open => !open);
          }} 
          tabIndex={1}
          className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white 
          text-sm font-medium text-gray-700 hover:bg-gray-50
          focus:outline-none focus:border-gray-500" 
          onMouseEnter={() => {
            setMenuOpen(false);
            setKeyOpen(true);
          }}
          aria-haspopup="true" 
          aria-expanded="true">
            {key}
            <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
          <Dropdown open={isKeyOpen} onClose={() => setKeyOpen(false)}>
            <div className="bg-white mt-2 rounded-md" onMouseLeave={() => setKeyOpen(false)}>
              <CircleOfFifths 
                onKeyChange={setKey}
                onScaleChange={setScale}
                root={key} 
                quality={currentScale && ['major', 'minor'].includes(currentScale.quality) ? currentScale.quality : 'major'} 
              />
            </div>
          </Dropdown>
        </div>

        <div className="w-auto px-1">
          <div className="w-64">
            <button onClick={(evt: MouseEvent) => {
              evt?.stopPropagation();
              setMenuOpen(open => !open);
            }} 
            tabIndex={1}
            className="inline-flex capitalize justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white 
          text-sm font-medium text-gray-700 hover:bg-gray-50
          focus:outline-none focus:border-gray-500" 
            onMouseEnter={() => {
              setMenuOpen(true);
              setKeyOpen(false);
            }}          
            aria-haspopup="true" 
            aria-expanded="true">
              {scale}
              <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            <Dropdown open={isMenuOpen} onClose={() => setMenuOpen(false)}>
              <div onMouseLeave={() => setMenuOpen(false)}>
                <ScaleMenu selected={scale} values={scales} onChange={(scale: ScaleExtended) => setScale(scale.name)}/>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <a href="/" className="invisible sm:visible">
        <img alt="logo" src="chordness.png" className="w-24"/>
      </a>
    </nav>
  );
};
