import { Midi } from "@tonaljs/tonal";
import { isNil } from "ramda";
import React, { FunctionComponent, useContext } from "react";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";

import { AudioContext } from "providers/AudioProvider";
import { keySelector, scaleSelector } from "state";
import {
  appendBlock,
  setActive,
  setBlock,
  setBlockId,
} from "state/song.reducer";
import { Block, Snapshot, Track } from "types";
import { GuitarChordType } from "types/GuitarChordType";

interface Props {
  chord: GuitarChordType;
  selected: boolean;
  degree: Snapshot["degree"];
  className?: string;
}

export const Chord: FunctionComponent<Props> = ({
  chord,
  degree,
  selected,
  className,
  ...props
}) => {
  const dispatch = useDispatch();
  const { arpeggiate } = useContext(AudioContext);
  const key = useSelector(keySelector);
  const scale = useSelector(scaleSelector);

  const [, dragRef] = useDrag({
    item: {
      type: "Chord",
      degree,
      chord,
      key,
      scale,
    },
    end: (item, monitor) => {
      if (!monitor.getDropResult()) {
        return;
      }
      const { block, track } = monitor.getDropResult();

      if (!isNil(block)) {
        const updatedBlock: Partial<Block> = {
          key,
          scale,
          chord: item?.chord,
          degree: item?.degree,
          pick: "strum",
          position: 0,
          id: block.id,
        };
        dispatch(setBlock(updatedBlock));
        return;
      }

      if (!isNil(track)) {
        const newBlock: { trackId: Track["id"] } & Partial<Block> = {
          key,
          scale,
          trackId: track.id,
          chord: item?.chord,
          degree: item?.degree,
          pick: "strum",
          position: 0,
        };
        dispatch(appendBlock(newBlock));
        return;
      }
    },
  });

  const play = () => {
    const [firstInversion] = chord.positions || [];
    const notes =
      firstInversion?.midi?.map((midiNote) => Midi.midiToNoteName(midiNote)) ||
      [];
    arpeggiate(notes);
  };

  const onClick = () => {
    dispatch(setBlockId());
    dispatch(
      setActive({
        chord,
        position: 0,
        degree,
      })
    );
    play();
  };

  const background = selected ? "bg-teal-500 text-white" : "text-gray-700";

  return (
    <div className="mx-4 my-5 relative w-12">
      {/* chord.intervals
        .filter((interval) => interval !== "1P")
        .map((interval, i) => (
          <span
            key={`interval-${interval}`}
            className="absolute rounded-full opacity-75 inline-flex items-center justify-center text-xs bg-teal-200 text-gray-900"
            style={{
              left: "37%",
              top: "37%",
              width: "1.1rem",
              height: "1.1rem",
              transform: `rotate(${
                -30 - 40 * i
              }deg) translate(-190%) rotate(${Math.abs(-30 - 40 * i)}deg)`,
              fontSize: "0.5rem",
            }}
          >
            {interval}
          </span>
        )) */}
      <span ref={dragRef}>
        <span
          key={chord.symbol}
          className={`${background} rounded-full p-2 flex-shrink-0 cursor-pointer font-display`}
          style={{}}
          onClick={onClick}
          {...props}
        >
          <span className="font-display font-thin text-xl">
            {chord.symbol[0]}
          </span>
          <span className="font-display font-thin text-sm tracking-wide">
            {chord.symbol.slice(1)}
          </span>
        </span>
      </span>
    </div>
  );
};
