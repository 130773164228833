import * as Tone from 'tone';

export default (() => {
  const synth = new Tone.Synth({
    oscillator: {
      type: 'sine',
    },
    envelope: {
      attack: 0,
      decay: 0.1,
      sustain: 0,
      release: 0.1,
    }
  }).toMaster();
  const part = new Tone.Part(
    (time, pitch) => {
      synth.triggerAttackRelease(pitch, "8n", time);
    },
    [
      ["0", 440],
      ["0:1", 220],
      ["0:2", 220],
      ["0:3", 220]
    ]
  );
    
  part.loop = true;
  part.loopStart = 0;
  part.loopEnd = "1:0";
  part.start(0);
  return part;
})();