import React, { FunctionComponent } from "react";
import "./styles/main.build.scss";
import { Provider } from 'react-redux';

import { Home } from "components/Home";
import { AudioProvider } from "providers/AudioProvider";
import { store } from 'state';

const App: FunctionComponent = () => {

  return (
    <Provider store={store}>
      <AudioProvider>
        <Home/>
      </AudioProvider>
    </Provider>
  );
};

export default App;
