import classes from "classnames";
import React, { FunctionComponent } from "react";

import { Fret as FretType } from "types";

interface Props extends FretType {
  onClick: () => void
}

export const Fret: FunctionComponent<Props> = ({
  active,
  visible,
  label,
  classNames,
  onClick
}) => (
  <div
    className={classes(
      "flex items-end justify-center c-fret cursor-pointer z-10",
      classNames
    )}
    onClick={onClick}
  >
    {visible && (
      <div className={
        classes(
          "text-xs c-fret__label", 
          { 
            "c-fret--active": active, 
            "hover:opacity-50": !active 
          }
        )
      }>
        {label}
      </div>
    )}
  </div>
);
